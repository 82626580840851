.bi::before {
  /* modify the bi (bootstrap icons) */
}

.bi-animation::before {
  background-size: 1rem 1rem; 
  color: red;

  animation: pulse 700ms infinite;
}

.bi-primary {
  color: #2196f3;
}

.bi-small {
  font-size: 1rem;
}

@keyframes pulse {
  50% {
    filter: brightness(1.2);
    transform: scale(1.2);
  }
}
