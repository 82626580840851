@import "./variable.scss";

.grid-container {
  width: 100%;
  margin: 0 auto;
}

.grid-container-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}

.grid-container-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.grid-container-2--fixed {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.grid-container-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

.grid-container-3--fixed {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-gap: 15px;
}

.grid-container-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}

.grid-container-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}

.grid-container-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
}

.grid-header-lhs-container {
  align-self: flex-end;
}

.grid-header-rhs-container {
  align-self: flex-end;
  justify-items: end;
}

.grid-about-me-container {
  display: grid;
  grid-template-columns: 28% 62%;
  grid-gap: 10%;
}

.grid-about-me-lhs-container {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, #66a5ad, transparent);
  display: grid;
  place-items: center;
}

.grid-skills-container-2 {
  display: grid;
  grid-template-columns: 0.1fr 0.9fr;
  grid-gap: 5%;
  align-items: start;
}

.grid-footer {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 15px;
}

.grid-item {
  background-color: $base-box-background-color;
  box-shadow: $base-box-shadow-color;

  padding: 20px;
  border-radius: 8px;

  font-size: 30px;
  text-align: center;

  transform: scale(1);
}

.grid-item-background-blur {
  background-color: rgba(255, 255, 255, 0);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.grid-item-blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.grid-item:hover {
  background-color: $base-box-background-color-hover;
  // box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
}

@media only screen and (max-width: 600px) {
  .grid-container-2,
  .grid-container-3,
  .grid-container-4,
  .grid-about-me-container,
  .grid-footer {
    grid-template-columns: 1fr;
  }

  .grid-container-6 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .grid-about-me-container {
    grid-gap: 5%;
  }

  .grid-about-me-lhs-container {
    width: 65%;
    margin: 0 auto;
  }
}
