@import "./variable.scss";

html,
body,
#root {
  font-family: Arial, Helvetica, sans-serif;
  /* background: #0a1f44; */
  background-color: $base-background-color;
  color: $base-primary-text-color;
  /* height: 100%; */
  margin: 0 auto;
  padding: 0 25px;

  max-width: 1240px;
  min-width: 320px;
}

.main-container {
  background-size: cover;
  background-position: center;
}

.main-container {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 120px;
}

header {
  display: contents;
  justify-content: center;
  align-items: center;
}

.header-button {
  z-index: 1;
}

.header-icon-set::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: #1e2432;
  margin-left: 7px;
}

.header-scroll-down {
  transform: rotate(90deg);
}

article {
  text-align: left;
}

section,
aside {
  width: 100%;
  text-align: center;
}

footer {
  width: 100%;
  height: 100px;
  // background-color: #f0f0f0;
}

img.showcase {
  position: block;
  width: 100%;
  height: 320px;
  border-radius: 8px;

  border: 1px solid red;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  /* transform: translateX(50%);
  opacity: 0;
  transition: all 500ms; */
}

div.about-me-img {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: all 200ms;
}

div.about-me-img:hover {
  transform: rotate(0);
}

div.propic-parent {
  max-width: 100%;
  height: 100%;
  margin: auto;
  z-index: 1;
}

div.propic-parent::after {
  display: block;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0,
    #fff 100%
  );
  margin-top: -50px;
  height: 50px;
  width: 100%;
  content: "";
}

img {
  display: block;
  width: 100%;
  height: 185px;

  border-radius: 8px;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @keyframes loaded {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  &.loaded:not(.has-error) {
    animation: loaded 300ms ease-in-out;
  }

  &.has-error {
    // content: url(${placeHolder});
  }
}

img.propic-child {
  border-radius: 0;
  left: calc(50% - 11rem);
  border-radius: 12rem 12rem 0 0;
}

img.full-percentage {
  width: 100% !important;
  height: 100% !important;
}

.clients {
  width: 100%;
  height: 100%;

  img {
    object-fit: contain;
  }
}

.fade {
  transform: translateX(0);
  opacity: 1;
  transition: all 500ms;
}

@media only screen and (max-width: 600px) {
  html,
  body,
  #root {
    padding: 0 5px;
  }

  header {
    margin-top: 80px;
  }

  .main-container {
    justify-content: initial;
    background: none;
  }

  .border-radius-item-3 {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  img {
    height: 320px;
  }
}
