h1 {
  margin: 0;
}

h2,
h3,
h4 {
  margin: 0 0 16px 0;
}

h2 {
  font-size: 13px;
  letter-spacing: 0.05em;
}

h3 {
  font-weight: normal;
  font-style: italic;
}

h4 {
  font-weight: 600;
}

p {
  font-size: 12px;
  margin-top: 11px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

p.bold {
  font-weight: bold;
}

p.quotation {
  font-size: 13px;
  color: #767676;
  font-weight: normal;
  font-style: italic;
  text-align: center;
}

p.hint {
  font-size: 11px;
  color: #767676;
}

p.overlay-text {
  color: white;
  position: absolute;
  top: 25%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
}

label {
  font-size: 13px;
  color: #66a5ad;
}

div.p {
  font-size: 12px;
  margin-top: 11px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

a {
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }
}
