@import "./variable.scss";

/* Ripple effect */
.ripple,
.ripple-ghost {
  background-position: center;
  transition: $base-transition;
}

.ripple:hover {
  background: $base-primary-button-color-hover
    radial-gradient(circle, transparent 1%, $base-primary-button-color-hover 1%)
    center/15000%;
  border-color: $base-primary-button-color-hover;
}

.ripple:active {
  background-color: $base-primary-button-color-active;
  background-size: 100%;
  transition: all 0s;
}

.ripple-ghost:hover {
  background: #66a5ad radial-gradient(circle, transparent 1%, #66a5ad 1%)
    center/15000%;
  color: #fff;
}

.ripple-ghost:active {
  background-color: #66a5ad;
  background-size: 100%;
  transition: all 0s;
}

/* Button style */
button {
  border: none;
  border-radius: $base-button-radius;
  padding: $base-button-padding;
  font-size: $base-button-font-size;
  font-weight: $base-button-font-weight;
  cursor: pointer;
  outline: none;
  z-index: 1;
}

.primary {
  color: $base-primary-button-text-color;
  background-color: $base-primary-button-color;
  border: 2px solid $base-primary-button-color;
  /* background-color: rgba(255, 255, 255, 0.8); */

  &-grad {
    background-image: linear-gradient(
      to right,
      $base-primary-button-color-hover 0%,
      $base-primary-button-color 51%,
      $base-primary-button-color-hover 100%
    );
    margin: 10px;
    padding: 15px 45px;
    transition: $base-transition;
    background-size: 200% auto;
    // box-shadow: 0 0 20px #eee;
    color: white;
  }

  &-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
}

.ghost {
  border: 2px solid $base-primary-button-color;
  color: $base-primary-button-color;
  background-color: white;
}
