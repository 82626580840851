@import "./variable.scss";

.border-radius-item-core {
  position: absolute;
  z-index: 0;
}

.border-radius-item {
  @extend .border-radius-item-core;
  width: 400px;
  height: 400px;
  background-color: $border-radius-item;
  border-radius: 74% 26% 70% 30% / 60% 30% 70% 40%;
  top: 50px;
  left: -100px;
}

.border-radius-item-2 {
  @extend .border-radius-item-core;
  width: 300px;
  height: 200px;
  background-color: $border-radius-item-2;
  border-radius: 73% 27% 57% 43% / 33% 63% 37% 67%;
  top: 500px;
  right: 50%;
}

.border-radius-item-3 {
  @extend .border-radius-item-core;
  width: 650px;
  height: 350px;
  background-color: $border-radius-item-3;
  border-radius: 36% 64% 57% 43% / 33% 67% 33% 67%;
  top: 50px;
  right: 0;
}

.border-radius-item-4 {
  @extend .border-radius-item-core;
  width: 300px;
  height: 400px;
  background-color: $border-radius-item-4;
  border-radius: 41% 59% 57% 43% / 42% 32% 68% 58%;
  top: -100px;
  left: 30%;
}

@media only screen and (max-width: 600px) {
  .border-radius-item-3,
  .border-radius-item-4 {
    display: none;
  }
}
