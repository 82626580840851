$base-primary-color: rgb(7, 86, 90);

$base-primary-button-color: rgb(7, 86, 90);
$base-primary-button-text-color: #fff;
$base-primary-button-color-hover: #003b46;
$base-primary-button-color-active: rgb(0, 50, 59);

$base-button-font-size: 12px;
$base-button-font-weight: bold;
$base-button-radius: 8px;
$base-button-padding: 12px 18px;

$base-transition: all 0s;
$base-radius: 8px;

$base-primary-text-color: #1e2432;
$base-primary-text-color-dark: rgb(233, 236, 239);

$base-background-color: #fff;
$base-background-color-dark: #0c0e12;

$base-box-background-color: rgba(255, 255, 255, 0.8);
$base-box-background-color-hover: rgb(196, 223, 230, 0.5);
$base-box-background-color-dark: rgb(26, 28, 32);
$base-box-background-color-dark-hover: rgb(43, 49, 57);

$base-box-shadow-color: rgba(149, 157, 165, 0.2) 0px 8px 24px;
$base-box-shadow-color-dark: rgb(24, 26, 31, 0.2) 0px 4px 4px;

$border-radius-item: rgba(7, 86, 90, 0.118);
$border-radius-item-2: rgba(7, 86, 90, 0.052);
$border-radius-item-3: rgba(7, 86, 908, 0.058);
$border-radius-item-4: rgba(7, 86, 908, 0.058);
$border-radius-item-dark: rgba(128, 128, 128, 0.118);
$border-radius-item-2-dark: rgba(128, 128, 128, 0.052);
$border-radius-item-3-dark: rgba(128, 128, 128, 0.058);
$border-radius-item-4: rgba(128, 128, 128, 0.058);
