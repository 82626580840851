@import "./variable.scss";

.img-overlay-container {
  position: relative;
}

// .img-overlay-container:hover .img-overlay {
//   opacity: 1;
// }

.img-overlay-layout-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  transition: height 0.5s;
}

.img-overlay-layout-2:hover {
  height: 100%;
}

.img-overlay-layout-2:hover .img-overlay {
  opacity: 1;
}

.img-overlay-layout-2:hover .img-overlay {
  opacity: 1;
}

.img-overlay-layout-2:hover .img-overlay-text {
  overflow: auto;
  white-space: normal;
  text-overflow: unset;
  padding: 18px;
  z-index: 1;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: $base-radius;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: $base-primary-color;
}

.img-overlay-text {
  color: white;
  background-color: $base-primary-color;
  position: absolute;
  top: 45%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
  // deal mobile screen if there is so much word
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.1s;
}

// Action e.g. more details, Live demo
.img-hint-overlay {
  position: absolute;
  bottom: 1.2rem;
  right: 1.5rem;
  border-radius: $base-radius;
  height: 3rem;
  width: 8rem;
  opacity: 1;
  transition: 0.5s ease;
  background-color: $base-primary-color;
}

.img-hint-overlay-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

// Topic e.g. company name
.img-topic-overlay {
  position: absolute;
  bottom: 0;
  margin: 0px;
  min-width: 0px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  backdrop-filter: blur(4px);
  width: 100%;
  padding: 2rem 0 2rem 0;
  text-align: left;
}

.img-topic-overlay-text {
  margin: 0px;
  min-width: 0px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  padding-left: 2rem;
  filter: blur(0px);
}

@media only screen and (max-width: 600px) {
  .img-topic-overlay {
    padding: 1rem 0 1rem 0;
  }

  .img-topic-overlay-text {
    width: 70%;
    font-size: 13px;
  }

  .img-hint-overlay {
    height: 2rem;
    width: 5rem;
    bottom: 0.7rem;
    right: 1.5rem;
  }

  .img-hint-overlay-text {
    font-size: 12px;
  }
}
