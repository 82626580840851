.m-0 {
  margin: 0;
}

.mt-8 {
  margin-top: 8px !important; 
}

.mt-16 {
  margin-top: 16px !important; 
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-32 {
  margin-bottom: 32px !important; 
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.tl {
  text-align: left
}

.p-32 {
  padding: 48px !important;
}

.p-48 {
  padding: 48px !important;
}

.ptb-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.ptb-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.plr-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}